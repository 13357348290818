window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

// Unused JS files should be commented out for performance improvement purposes, uncomment if needed.

$(document).ready(function () {
    processInclude(require('telco/components/addressCheckerWidget.js'));
    // processInclude(require('base/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('base/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    // processInclude(require('base/components/search'));
    processInclude(require('telco/components/clientSideValidation'));
    // processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('telco/components/addToCartButton'));
    processInclude(require('telco/components/timeExpiredModal'));
    processInclude(require('telco/components/globalModals'));
    processInclude(require('telco/accessibility'));
    processInclude(require('zuora/zuora/zuora'));
    processInclude(require('gtm/gtm'));
    processInclude(require('telco/components/failedToLoad'));
    processInclude(require('telco/components/leadCreationFailedModal.js'));
    processInclude(require('telco/components/header'));
    processInclude(require('telco/components/openBonusDetailsModal.js'));
    processInclude(require('telco/components/qfmodals.js'));
    processInclude(require('telco/components/mpVoiceResume.js'));
    processInclude(require('telco/components/footer'));
    processInclude(require('telco/components/floatingCart'));
    processInclude(require('telco/components/popover'));
    processInclude(require('telco/components/mpMessageCenter'));
    processInclude(require('telco/components/mpSearch'));
    processInclude(require('telco/components/paymentFailureModal.js'));
    processInclude(require('telco/components/langToggle.js'));
});

require('telco/thirdParty/bootstrap');
require('telco/thirdParty/select2');
require('telco/thirdParty/jquerymask');
require('base/components/spinner');
require('telco/thirdParty/selectize');
require('./helpers/lazyload');
require('slick-carousel/slick/slick');
