'use strict';

const cache = {
    $qfErrorModal: $('#qf-modal-error')
};


module.exports = {
    initEvents: function () {
        // Stops spinner when modal closes
        cache.$qfErrorModal.on('show.bs.modal', function () {
            $('.js-refresh-btn').on('click', function () {
                location.reload();
            });
            $('.js-back-btn').on('click', function () {
                location.reload();
            });
            $.spinner().stop();
        });

        // Set timeout across the board
        $.ajaxSetup({
            timeout: cache.$qfErrorModal.data('failed-to-load-timeout')
        });
    }
};
