'use strict';

const cache = {
    $body: $('body'),
    $hamburgerMenu: $('#header .hamburger-menu__trigger'),
    $hamburgerMenuContainer: $('#header .header__hamburger-menu'),
    $hamburgerMenuClose: $('#header .hamburger-menu__list .icon__x-close, #header .icon__close-purple-x, #maincontent, #footercontent'),
    $hamburgerMenuSubNav: $('#header .hamburger-menu .navigation__list-item-sub-navigation'),
    $hamburgerMenuSubNavLink: $('#header .hamburger-menu .navigation__list-item-sub-navigation .navigation__link'),
    $navListItemSubNav: $('#header .navigation__list-item-sub-navigation'),
    $navLink: $('#header .navigation__link'),
    $hamburgerMenuMp: $('.hamburger-menu-lg'),
    $hamburgerMenuContainerMp: $('#header .hamburger-menu-mp, #header .hamburger-menu'),
    $hamburgerMenuContainerMpSupport: $('#header .hamburger-menu-mp .hamburger-menu__support'),
    $bannerCloseBtn: $('.banner__close'),
    $bannerInfoMsg: $('.mp__banner-info'),
    $headerNavMpListItem: $('.header__navigation-mp .navigation-mp__list-item .navigation__link'),
    $headerSupportMpListItem: $('.support__list-item .support__link'),
    $jsMoversDropdownToggle: $('.js-movers-dropdown-toggle')
};

/**
 * Adds an Underline that indicates the category of the page you are currently on.
 */
function navbarIndicator() {
    let url = window.location.href;
    let $navLink = $(`#header .navigation__link[href$='${url}']`);
    let $mobileLinkVoid = $('.manage-portal #header .hamburger-menu__link[href$=\'javascript:void(0)\']');
    let $subNavLink = $(`#header .sub-navigation__link[href$='${url}']`);
    let $mobileLink = $(`#header .hamburger-menu__link[href$='${url}']`);
    let $mobileSubNavLink = $(`#header .sub-navigation__mobile-link[href$='${url}']`);

    $navLink.addClass('navigation__link-active');
    $subNavLink.addClass('sub-navigation__link-active');
    $mobileLink.addClass('hamburger-menu__link-active');
    $mobileSubNavLink.addClass('sub-navigation__mobile-link-active');
    $mobileLinkVoid.addClass('hamburger-menu__link-hidden');

    if (!$mobileLink.parent().hasClass('d-xl-none')) {
        $mobileLink.offsetParent().find('.hamburger-menu-lg').addClass('hamburger-menu-active');
    }

    let $activeSubNavLink = $('#header .sub-navigation__link-active');

    $activeSubNavLink.closest(cache.$navListItemSubNav).find(cache.$navLink).addClass('navigation__link-active');
}

/**
 * closing banner
 */
function closedBanner() {
    cache.$bannerCloseBtn.on('click', () => {
        cache.$bannerInfoMsg.hide('fast');
        cache.$bannerInfoMsg.parent('.mp-header__banner').hide('fast');
    });
}

/**
 * fadeToggleMenu
 */
function fadeToggleMenu() {
    cache.$hamburgerMenuContainerMpSupport.fadeToggle('fast', 'linear');
}

module.exports = {
    initEvents: function () {
        // Toggle mobile support menu visibility
        cache.$hamburgerMenu.on('click', function () {
            fadeToggleMenu();
            cache.$hamburgerMenuContainerMp.toggleClass('header_show');
            cache.$body.toggleClass('overflow-hidden');
        });

        // Turn off mobile support menu visibility when clicked outside of header & on the x icon
        cache.$hamburgerMenuClose.on('click', function () {
            cache.$hamburgerMenuContainerMpSupport.hide();
            cache.$body.removeClass('overflow-hidden');
        });

        // Toggle subnavigation menu in mobile
        cache.$hamburgerMenuSubNav.on('click', function () {
            cache.$hamburgerMenuSubNav.toggleClass('hide_subnav');
        });

        // Toggle mobile support menu visibility
        cache.$hamburgerMenuMp.on('click', function (e) {
            e.preventDefault();
            fadeToggleMenu();
            cache.$hamburgerMenuContainerMp.toggleClass('header_show');
            cache.$hamburgerMenuMp.toggleClass('header_myaccount_menu');
        });

        // Turn off mobile support menu visibility when clicked outside of header & on the x icon
        cache.$hamburgerMenuClose.on('click', function () {
            cache.$hamburgerMenuContainerMp.removeClass('header_show');
            cache.$hamburgerMenuMp.removeClass('header_myaccount_menu');
        });

        // GTM Header Navigation click
        cache.$headerNavMpListItem.on('click', function () {
            let navItemId = $(this).get(0).id;
            const navMenu = {
                MPHome: 'home',
                MPPayments: 'billing',
                MPAccount: 'service',
                MPAppointments: 'my appointments',
                MPShop: 'shop'
            };
            let gtmPushObj = {
                event: 'qf_navigation_bar_click',
                flowStep: 'qf navigation bar click',
                buttonName: navMenu[navItemId]
            };
            $('body').trigger('gtm:mp:pushDataLayerEvent', gtmPushObj);
            return true;
        });

        // GTM Support Link click
        cache.$headerSupportMpListItem.on('click', function () {
            let gtmPushObj = {
                event: 'qf_navigation_bar_click',
                flowStep: 'qf navigation bar click',
                buttonName: 'my account'
            };
            $('body').trigger('gtm:mp:pushDataLayerEvent', gtmPushObj);
            return true;
        });

        cache.$jsMoversDropdownToggle.on('click', function () {
            $('.movers-dropdown__list').toggle();
        });

        navbarIndicator();
        closedBanner();
    }
};
