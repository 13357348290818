'use strict';

/**
* Generates URL for switching between language versions.
* @param {boolean} isSpanish - Indicates if the URL should switch to the Spanish version.
* @returns {string} - The modified URL for the desired language.
*/
function getSwitchLanguageURL(isSpanish) {
    var currentURL = new URL(window.location.href);
    var hostname = currentURL.hostname;

    hostname = isSpanish ? hostname.replace('www.', 'es.') : hostname.replace('es.', 'www.');

    currentURL.hostname = hostname;

    return currentURL.toString();
}

module.exports = {
    initEvents: function () {
        if ($('.lang-container').length) {
            var isSpanish = window.location.hostname.startsWith('es.');
            var enLink = $('.en-version');
            var esLink = $('.es-version');

            if (isSpanish) {
                esLink.addClass('disabled-style');
            } else {
                enLink.addClass('disabled-style');
            }

            enLink.attr('href', getSwitchLanguageURL(false));
            esLink.attr('href', getSwitchLanguageURL(true));
        }
    }
};
